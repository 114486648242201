import * as config from "./config";
import * as pageProcessing from "./pageProcessing";
import * as eventProcessing from "./eventProcessing";
import * as failsafe from "./failsafe";
import * as utilities from "./utilities";
import * as pubSub from "./pubSub";
import * as appData from "./appData";
import * as idservice from './idservice';
import * as pagehide from './pagehide';

// Conditional imports.  If not used based on site conditions below webpack will strip these from the bundle.
import * as perimeterx from "./perimeterx";
import * as staticAppMeasure from "./appMeasure";
import * as staticVisitorAPI from "./visitorAPI";

let visitorAPIisFromCore = false;

var setupModules = async function( ) {

	let appMeasurementInit;

    try {
               
		// We only want one file for GCC with no dynamic imports.
		if ( BUILD_TARGET === "blinds" || BUILD_TARGET === "blindsca" || BUILD_TARGET === "justblinds" || BUILD_TARGET === "americanblinds" ) {
			appMeasurementInit = staticAppMeasure.init();
		} else {
			let dynamicAppMeasure = await import( /* webpackChunkName: "appMeasure", webpackPrefetch: true */ './appMeasure' );
			appMeasurementInit = dynamicAppMeasure.init();
		}

		console.log( "ANALYTICSLOG -- appMeasure is loaded: ", appMeasurementInit );
        console.log( "ANALYTICSLOG -- APPCONTROLLER -- window.s", window.s );

		// override must be set up after appMeasure is iniited.
		if (appMeasurementInit.initialized) {
			window._T.deployments.core.appMeasurement = appMeasurementInit.appMeasurementVersion;			
		}

        if ( config.featureEnabled( 'failSafeBeacon' ) ) {
            failsafe.init();
        }

        if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2consumer-mobile" || BUILD_TARGET === "b2business-desktop" || BUILD_TARGET === "b2business-mobile" ) {
			
		
			if ( appMeasurementInit.initialized ) {
				
				let defaultActivityMapRegion = window.s.ActivityMap.region;

				window.s.ActivityMap.region = function ( clickEvent ) {
					
					function _findClickID( el ) {
						
						if ( !el || typeof ( el.getAttribute ) !== 'function' ) return null;

						let found = el.getAttribute( "clickid" );

						if ( found ) return found;
						if ( el.parentNode ) return _findClickID( el.parentNode );

						return null;
					}
					
					let cid = _findClickID( clickEvent );
									
					return cid ? cid : defaultActivityMapRegion( clickEvent );
				}

			}
			
            if ( config.featureEnabled( 'ecridsync' ) ) {
                idservice.init();
            }

            // Calling the inital perimeterx script
            if ( config.featureEnabled( "perimeterx" ) && !window._pxAppId ) {
                perimeterx.init();
            }

        }

    } catch ( error ) {
        console.error( `ANALYTICSLOG -- Error: ${ error }` );
    }

};

var getTempEventData = function ( digitalData ) {

	var tempEventData = [];

	if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2consumer-mobile" || BUILD_TARGET === "b2business-desktop" || BUILD_TARGET === "b2business-mobile" || BUILD_TARGET === "tcs" ) {
		// capture any digitalData Events that were pushed before the async code loaded
		tempEventData = ( typeof digitalData.event !== 'undefined' && digitalData.event.constructor === Array && digitalData.event.length ? digitalData.event : [] );
	}

	if ( BUILD_TARGET === "blinds" || BUILD_TARGET === "blindsca" || BUILD_TARGET === "justblinds" || BUILD_TARGET === "americanblinds" ) {
		tempEventData = ( _T.eventData ? _T.eventData : [] );
	}

	return tempEventData;

};

var exposeItemsOnInterface = function () {

	_T.getPgBeaconDelay = config.getPgBeaconDelay;

	// Used to pass events to core analytics upon user action or confirmation of successful action.
	_T.sendPageDataToAnalytics = pageProcessing.sendPageDataToAnalytics;
	_T.sendEventDataToAnalytics = eventProcessing.sendEventDataToAnalytics;
	_T.pageData = pageProcessing.pageDataArray();

};

var processTempPageData = function () {

	var _tempPageData = ( typeof _T.pageData !== 'undefined' && _T.pageData.constructor === Array && _T.pageData.length ? _T.pageData[ _T.pageData.length - 1 ] : "" );

	exposeItemsOnInterface();

	if ( _tempPageData ) {
		appData.addUpdateCache( "beaconExecutionType", "preignition" );
		_T.pageData.push( _tempPageData );
	}

};

var run = async function( ddo ) {

    utilities.validateDataObj( ddo );

    var _tempEventData = getTempEventData( ddo );

	if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2consumer-mobile" || BUILD_TARGET === "b2business-desktop" || BUILD_TARGET === "b2business-mobile" || BUILD_TARGET === "tcs" ) {
        ddo.event = eventProcessing.eventDataArray();
	}

	/* Set TRON Cache and add pageName */
    appData.addUpdateCache( "pageName", typeof ddo.page.pageInfo.pageName === "string" ? ddo.page.pageInfo.pageName.toLowerCase() : '' );

    await setupModules( ddo );

    await eventProcessing.processPreIgnitionEvents( _tempEventData );

	processTempPageData();

	if ( config.featureEnabled( "perimeterx" ) ) {
        perimeterx.initListeners( ddo );
	}

};

// iframed Overlay tracking is currently handled through the parent page.  In other words, the analytics events are passed from the Iframe to
// its parent page for execution.  We use the _router method to ensure that the Bootstrap that IS included on the Iframe Overlay does not execute
// any analtics calls (page or events) but allows for the delivery of certain 3rd party tags (ClickTale and TnT primarily).
var _router = function( ddo ) {

	if ( !utilities.isOverlay() ) {
        run( ddo );
	}
	else {
		_T.getPgBeaconDelay = config.getPgBeaconDelay;
	}

};

let continueInitialization = function() {

	if ( !_T.PUB_SUB ) {
		_T.PUB_SUB = pubSub.init();
	}

    window._T.deployments.core.visitorAPI = ( Visitor ? Visitor.version : "na" );
    window._T.deployments.core.visitorAPIisFromCore = visitorAPIisFromCore;

	if ( !utilities.isOverlay() ) {
		pagehide.init();
	}

	if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2consumer-mobile" || BUILD_TARGET === "b2business-desktop" || BUILD_TARGET === "b2business-mobile" || BUILD_TARGET === "tcs" ) {
		

		if ( document.readyState === "complete" ) {
			_router( window.digitalData );
		}
		else {
			document.addEventListener( 'readystatechange', function () {
				if ( document.readyState === "complete" ) {
					_router( window.digitalData );
				}
			} );
		}
		
	}
	else {
		_router( window.digitalData );
	}

};

( function() {
    // An indicator for build_local mappings 
    console.log( '%c ANALYTICSLOG -- Local Build Mapping: Core | ' + CODE_VERSION + ' | ' + BUILD_TARGET + ' | 2024-08-07T13:24:59.797Z', 'background: orange; color: white; display: block; line-height:20px; padding:5px 30px; border: 1px solid red; font-size:1em;' );

    window._T = ( window._T ? _T : {} );

    window.digitalData = ( window.digitalData ? digitalData : {
        event: []
    } );

    _T.pageData = ( _T.pageData ? _T.pageData : [] );
    _T.eventData = ( _T.eventData ? _T.eventData : [] );

    window._T.deployments = ( _T.deployments ? _T.deployments : {} );

    window._T.deployments.core = {
        build: BUILD_TARGET,
        versions: {
            core: CODE_VERSION
        },
        ts: '2024-08-07T13:24:59.797Z',
        host: utilities.getAnalyticsHost()
    };

    if ( !window.Visitor ) {

		// We only want one file for GCC with no dynamic imports.
		if ( BUILD_TARGET === "blinds" || BUILD_TARGET === "blindsca" || BUILD_TARGET === "justblinds" || BUILD_TARGET === "americanblinds" ) {

			staticVisitorAPI.init();
			continueInitialization();

		} else {

			import( /* webpackChunkName: "visitorAPI" */ './visitorAPI' )
				.then( ( visitorAPI ) => {
					visitorAPI.init();
					visitorAPIisFromCore = true;
					continueInitialization();
				} )
				.catch( ( error ) => {
					console.error( `ANALYTICSLOG -- Error: ${ error }` )
				} );

		}

    } else {
        continueInitialization();
    }

} )();