const COOKIE_KEY = "stsh";

let eventData = {}, // Used to store stash data for current page
    persistedData = {}, // Data persistsed from previous page
    willPersist = {}; // Data to persist for next page

let addToEventData = function ( key, val ) {
    //TODO: check with team on whether we need to consider duplicates here, as it stands we would overwrite duplicate keys
    eventData[ key ] = val;
};

let appendToEventData = function ( key, val, delim ) {
    delim = ( typeof ( delim ) !== 'undefined' ? delim.toString() : '|' );

    let current = eventData[ key ];

    eventData[ key ] = ( typeof ( current ) !== 'undefined' ? current.toString() + delim + val.toString() : val.toString() );
};

let addToWillPersist = function ( key, val ) {
    //TODO: check with team on whether we need to consider duplicates here, as it stands we would overwrite duplicate keys
    willPersist[ key ] = val;
    s.c_w( COOKIE_KEY, JSON.stringify( willPersist ) );
};

/**
 * Grabs data from stash based on the given key
 * @param: {string} key - name of the stash event to look for
 * @returns: {object} - stash data object assosciated to given key
 */
let getEventData = function ( key ) {
    let data = eventData[ key ];
    return ( typeof data !== "undefined" ? data : "" );
};

/**
 * Deletes data from stash based on the given key
 * @param: {string} key - name of the stash event to look for
 * @returns: {boolean} - true - if found and deleted,false otherwise
 */
let deleteEventData = function ( key ) {
    let data = eventData[ key ];
    delete eventData[ key ];
    return ( typeof data !== "undefined" );
};

/**
 * Deletes data from persisted stash based on the given key
 * @param: {string} key - name of the persisted stash event to look for
 * @returns: {boolean} - true - if found and deleted,false otherwise
 */
let deletePersistedData = function ( key ) {
	let data = persistedData[ key ];
	delete persistedData[ key ];
	return ( typeof data !== "undefined" );
};

/**
 * Deletes ALL data from persisted stash
 * @returns: {boolean} - true - if found and deleted,false otherwise
 */
let clearPersistedData = function ( ) {
    persistedData = {};

    //clear persisted data cookie
    s.c_w( COOKIE_KEY, "" );

	return true;
};

/**
 * Grabs data from persisted stash based on the given key
 * @param: {string} key - name of the stash event to look for
 * @returns: {object} - persisted stash data object assosciated to given key
 */
let getPersistedDataWithKey = function ( key ) {
    let data = persistedData[ key ];
    return ( typeof data !== "undefined" ? data : "" );
};

/**
 * Grabs data from persisted stash based on the given key
 * @param: {string} key - name of the stash event to look for
 * @returns: {object} - persisted stash data object assosciated to given key
 */
let getPersistedDataObject = function () {
    let data = persistedData;
    return ( typeof data !== "undefined" ? data : {} );
};

/**
 * Reads stash cookie for any previously persisted data
 * @param: na/
 * @returns: {object} - Persisted stash data (key/value pairs)
 */
let getParsedCookie = function () {
    let cStash = s.c_r( COOKIE_KEY );
    return ( cStash ? JSON.parse( cStash ) : undefined );
};

let onLoad = function () {

    // Read Data from Stash Cookie
    let stash = getParsedCookie();    
    console.log( "ANALYTICSLOG -- STASH BEGIN ONLOAD - Data From cookie: ", stash );

    // store for later access
    persistedData = ( stash ? stash : {} );

    //clear persisted data cookie
    s.c_w( COOKIE_KEY, "" );

    //clear internal persisted values
    willPersist = {}
    
    console.log("ANALYTICSLOG -- STASH AFTER ONLOAD", { 
        "persistedData": persistedData,
        "eventData" : eventData,
        "willPersist": willPersist 
    });
};

export {
    addToEventData,
    addToWillPersist,
    appendToEventData,
	clearPersistedData,
    deleteEventData,
    deletePersistedData,
    getEventData,
    getPersistedDataObject,
    getPersistedDataWithKey,
    onLoad
};