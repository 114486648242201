import * as config from "../src/config";
import * as appData from "../src/appData";
import * as eventProcessing from "../src/eventProcessing";
import * as utilities from "../src/utilities";

var init = function () {

    // set PROD or DEV ID
	window._pxAppId = ( config.isProdDeployment() ? 'PXJ770cP7Y' : 'PX04KmKlXI' );

    // Register Async Event for hashed score
    window[ window._pxAppId + '_asyncInit' ] = function ( px ) {
        px.Events.on( 'score', function ( score ) {
            // Set your action for the score here - can go to another variable or function. In this case we just log it to the console.
            try {

                _T.pxbs = score;
            }
            catch ( e ) {
                console.log( "ANALYTICSLOG -- Core perimeterx error: ", e );
            }
        } );

        //enrichment function
        px.Events.on( 'enrich', function ( value ) {
            // Set your action for the score here - can go to another variable or function. In this case we just log it to the console.
            try {
                const base64Data = ( typeof value === "string" && value.split ) ? value.split( ":" )[ 1 ] : null; // split value to get the base64 encoded data
                if ( base64Data ) {
                    const dataStr = atob( base64Data ); // base64 decode the enrichment data
                    const data = JSON.parse( dataStr ); // get the data as JSON
                    _T.pxEnrich = data.inc_id;
                }
            }
            catch ( e ) {
                console.log( "ANALYTICSLOG -- perimeterx error: ", e );
            }
        } );
    };

    /** Passing adobe MCMID as second parameter to perimeterx **/
    var markCookie = window.s.c_r( "AMCV_F6421253512D2C100A490D45@AdobeOrg" ),
        markCookSplit = markCookie.split( "MCMID|" );
    window._pxParam2 = ( markCookie && markCookSplit && markCookSplit[ 1 ] && markCookSplit[ 1 ].split( "|" )[ 0 ] ? markCookSplit[ 1 ].split( "|" )[ 0 ] : "" );

    /**
     * going to pass '1' to px as the 3 param if the customer lands on the confirmation page for better tracking
     */
    if ( location.pathname === "/mycheckout/thankyou" ) {
        window._pxParam3 = '1';
    }

	var p = document.getElementsByTagName( "script" )[ 0 ],
		s = document.createElement( "script" );
	s.async = 1;
	s.src = "//client.px-cloud.net/" + window._pxAppId + "/main.min.js";
	p.parentNode.insertBefore( s, p );

};
/* eslint-disable */
var initListeners = function ( ddo ) {
	if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2business-desktop" || BUILD_TARGET === "b2x-desktop" ) {
		/*Intergrations with PX defender tracking */
		document.addEventListener( '__px_event', ( e ) => {
			if ( e.detail && e.detail.event && e.detail.event === 'page_infected' && typeof ( e.detail.protected ) !== 'undefined' ) {

				let pxCookie = utilities.read( 'pxdefender' );
				utilities.write( 'pxdefender', e.detail.protected );

				if ( pxCookie === '' && appData.getFromCache( "pageViewSent" ) ) {

					appData.addInternalEvents( {
						"category": {
							"primaryCategory": "interaction"
						},
						"eventInfo": {
							"eventName": "perimeterx defender"
						},
						"eventData": e.detail.protected
					} );

					eventProcessing.processInternalEvents( ddo );
				}

			}
		} );
	}
	return true;
}
/* eslint-enable */
export {
    init,
	initListeners
};
