var internalEvents = [];
var preloadEvents = [];
var cache = {};
var ddoInstance = {};

var getPreloadEvents = function (){
	return preloadEvents;
};

var addPreloadEvents = function ( val ){
	preloadEvents.push( val );
};

var clearPreloadEvents = function () {
	preloadEvents = [];
};

var getInternalEvents = function (){
	return internalEvents;
};

var addInternalEvents = function ( val ){
	internalEvents.push( val );
};

var clearInternalEvents = function () {
	internalEvents = [];
};

var getFromCache = function ( key, notfound = "" ) {
	return ( typeof ( cache[ key ] ) !== "undefined" ? cache[ key ] : notfound );
};

var addUpdateCache = function ( key, val ) {
	cache[ key ] = val;
};

var clearCache = function () {
	cache = {};
};

var getDDOInstance = function ( instanceID ) {
	return ddoInstance[ instanceID ];
};

var addUpdateDDOInstance = function ( instanceID, val ) {
	ddoInstance[ instanceID ] = val;
};

export {	
	getInternalEvents,
	addInternalEvents,
	clearInternalEvents,
	getPreloadEvents,
	addPreloadEvents,
	clearPreloadEvents,
	getFromCache,
	addUpdateCache,
	clearCache,
	getDDOInstance,
	addUpdateDDOInstance
};
