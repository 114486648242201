import { addInternalEvents, addUpdateCache, getFromCache } from "./appData";
import { processInternalEvents } from "./eventProcessing";
import { isConsumerApp } from "./utilities";

let isSubscribedToPageHide = false;

const _KEY = "_PAGEHIDE_DATA";

const eventPush = ( ddo ) => {

    const data = getFromCache( _KEY ) || false,
          _reset = () => {
        
        console.log( 'ANALYTICSLOG -- reset after pagehide call: ');
            
        addUpdateCache( _KEY, "" ); //reset cached pagehide data
        addUpdateCache( "_Impressions", "" ); //reset impression data, defined in the batch*viewport_impression event in daMap  
        addUpdateCache("_IPAudit", "");  //reset impression audit data, defined in the stash*viewport_impression_audit event in daMap 
        
        //Reset the items that control duplicate suppression
        addUpdateCache( "pageCurrentlyBeingProcessed", "");
        if ( Array.isArray( window.top?.digitalData?.event) ) { window.top.digitalData.event.length = 0; }  
        _T.PUB_SUB.broadcastArchive = [];

    };

    // Exit if no data collected
    if ( !data ) { _reset(); return; }

    console.log( 'ANALYTICSLOG -- pageHide eventPush' );
    addInternalEvents( {
        "category": {
            "primaryCategory": "page lifecycle"
        },
        "eventInfo": {
            "eventName": "pagehide"
        },
        "data": data        
    } );

    processInternalEvents( ddo );

    _reset();
};

const init = () => {

    // Handle SPA Transitions, which includes hybrid PLP paginations	
	(function() {

        let count = 0,
            limit = 10,
            inter = setInterval( function() {

            count++;
            let listenerSet = false;

            if ( typeof window.LIFE_CYCLE_EVENT_BUS === 'undefined' && count <= limit ) return;
            console.log("ANALYTICSLOG -- LIFE_CYCLE_EVENT_BUS Found");
            
            if ( window.LIFE_CYCLE_EVENT_BUS && typeof ( window.LIFE_CYCLE_EVENT_BUS?.on ) === 'function' ) {

                listenerSet = true;

                console.log("ANALYTICSLOG -- resetImpressionProvider LISTENER SET");

                window.LIFE_CYCLE_EVENT_BUS.on( 'router.change', function () {

                    console.log("ANALYTICSLOG -- SPA TRANSITION LISTENER FIRED");
                    addUpdateCache( "beaconExecutionType", "unload" );
                    _T.PUB_SUB.publish( "thdcoreanalytics|pagehide");

                });

                
            }
            
            if ( listenerSet || count >= limit ) {
                console.log("ANALYTICSLOG -- PageHide LIFE_CYCLE_EVENT_BUS INTERVAL CLEARED", listenerSet, count, limit);
                clearInterval( inter );
            }

        }, 100, count, limit );

	}());
        
    if ( isConsumerApp() ) {

       // Use Visibility Change Event when on Consumer APP
       document.onvisibilitychange = () => {

            console.log( 'ANALYTICSLOG -- Visibility State: ', document.visibilityState);
            
            if ( document.visibilityState === "hidden" ) {
                
                addUpdateCache( "beaconExecutionType", "unload" );
                _T.PUB_SUB.publish( "thdcoreanalytics|pagehide");                

            }
       };
  
   } else {

        window.addEventListener( 'pagehide', function( event ) {

            if ( event.persisted === true ) {
                console.log( 'ANALYTICSLOG -- This page *might* be entering the bfcache.' );
                addUpdateCache( "beaconExecutionType", "mightBeEnteringBFCache");
            } else {
                console.log( 'ANALYTICSLOG -- This page will unload normally and be discarded.' );
                addUpdateCache( "beaconExecutionType", "unload" );
            }

            _T.PUB_SUB.publish( "thdcoreanalytics|pagehide" );

        }, false);

    }
    /**
     * Listen for events that need to be bundled on global pagehide beacon
     * @param { e } array of objects with following shape     
     * 
        [{				
            "key": 'eVarXXX|propXXX|eventXXX|listXXX',
            "value": ""
        }]
     *       
     * FOR EXAMPLE:
     * [{				
            "key": 'eVar106',
            "value": '309420742:like'
		}]
     */
    _T.PUB_SUB.subscribe( 'thdcoreanalytics|batch', function ( e ) {

        //exit if we dont have proper data
        if ( !Array.isArray( e ) ){ return; }

        // Subscribe to PageHide listener once we receive our first batch request
        if ( !isSubscribedToPageHide ) {

            isSubscribedToPageHide = true;

            _T.PUB_SUB.subscribe( 'thdcoreanalytics|pagehide', function () {                
                eventPush( window.digitalData );
            } );

        }
                
        // get any previously stored data
		let data = getFromCache( _KEY ) || {};

        // Update Data
        e.forEach( obj => { data[obj.key] = obj.value; });
        
        console.log( "ANALYTICSLOG -- batch event", data , e );
        addUpdateCache( _KEY, data );

	} );

};

export {
    init
  };