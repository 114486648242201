import * as appData from "../src/appData";

// Conditional imports.  If not used based on site conditions below webpack will strip these from the bundle.
import * as staticLodashCloneDeep from "lodash-es/cloneDeep";

/**
 * Reads the hda session storage or cookie
 * @param {string} keyName
 * @returns {*}
 */
var read = function ( keyName ) {

    keyName = ( keyName && keyName !== "hda" ) ? keyName : "";

    let hda;
    let returnVal = "";

    try {
        hda = typeof Storage !== "undefined" ? sessionStorage.getItem( "hda" ) : '';
    }
    catch ( e ) {
        console.log( "ANALYTICSLOG -- Error reading Storage: ", e );
    }

    if ( hda ) {
        hda = JSON.parse( hda );
        if ( keyName ) {
            returnVal = ( hda[ keyName ] ) ? hda[ keyName ] : "";
        }
        else {
            returnVal = hda;
        }
    }

    return returnVal;

};

/**
 * Write value to session storage or cookie as available
 * @param {string} name
 * @param {string} value
 * @param {date & time} exp
 */
var write = function ( name, value ) {
    var jsonStore = read(),
        strStore;

    if ( jsonStore ) {
        jsonStore[ name ] = value;
        strStore = JSON.stringify( jsonStore );
    }
    else {
        let newDate = new Date();
        let timeUpdate = newDate.getHours() + ':' + newDate.getMinutes() + ':' + newDate.getSeconds();
        strStore = "{\"" + name + "\":\"" + value + "\",\"t\":\"" + timeUpdate + "\"}";
    }

    var storeUsed = "";

    try {
        if ( typeof ( Storage ) !== "undefined" ) {
            sessionStorage.setItem( "hda", strStore );
            storeUsed = "localStorage:" + strStore;
        }
    }
    catch ( e ) {
        console.log( "ANALYTICSLOG -- Error writing to Storage: ", e );
    }

    return storeUsed;
};

var cookieRead = function ( e ) {
	e = encodeURIComponent( e );
	var t = ( ";" + document.cookie ).split( " " ).join( ";" ),
		n = t.indexOf( ";" + e + "=" ),
		i = n < 0 ? n : t.indexOf( ";", n + 1 );
	return n < 0 ? "" : decodeURIComponent( t.substring( n + 2 + e.length, i < 0 ? t.length : i ) )
};

var uReplace = function ( a, b, d ) {
	return !a || 0 > a.indexOf( b ) ? a : a.split( b ).join( d )
}

var unescape = function ( c ) {
	if ( !c ) return c;
	c = 0 <= c.indexOf( "+" ) ? uReplace( c, "+", " " ) : c;
	try {
		return decodeURIComponent( c )
	}
	catch ( b ) {}
	return unescape( c )
};

/**
 * Read Key-Value pair from specified cookie and return value
 * @param {string} cookieName
 * @param {string} keyName
 * @param {string} keySplit
 * @param {boolean} stripTimeFromS_PERS
 * @returns {string}
 * example: readCookieKeyValue("s_sess", "s_pv_pName", ";", true);
 */

var readCookieKeyValue = function ( cookieName, keyName, keySplit = ";", stripTimeFromS_PERS ) {
    var key;
    var value = "";
    var pair = [];

    // returns the decoded cookie value or an empty string if the cookie is not found
    var cookieString = cookieRead( cookieName );

    var cookieArray = cookieString.split( keySplit );

    for ( var i = 0; i < cookieArray.length; i++ ) {
        pair = cookieArray[ i ].split( '=' );

        key = pair[ 0 ].trim();

        if ( key === keyName ) {
            value = unescape( pair[ 1 ] ).trim();
            if ( stripTimeFromS_PERS ) {
                value = value.replace( /\|\d+/g, "" );
            }
            break;
        }
    }

    return value;

};

var checkArray = function ( aryObj ) {
    var _i = 0,
        _a,
        _len = aryObj.length,
        totalMatchers = arguments.length - 1,
        matcher,
        target;

    while ( _i < _len ) {
        //check if aryObj is single or multidimensional array
        target = Array.isArray( aryObj[ _i ] ) ? aryObj[ _i ][ 0 ] : aryObj[ _i ];
        //if multi arguments loop through test
        for ( _a = 1; _a < totalMatchers + 1; _a++ ) {
            matcher = arguments[ _a ];
            if ( matcher.indexOf( target ) > -1 ) {
                return ( Array.isArray( aryObj[ _i ] ) && ( aryObj[ _i ].length > 1 ) ? aryObj[ _i ][ 1 ] : aryObj[ _i ] );
            }
        }
        _i++;
    }
};

/**
 * Determine what Host the Analytics platform is being provided through
 * @returns {string}: 'GCP' - Google GDN, 'AKA' - Akamai CDN, 'ENS' - Ensighten CDN,  '' - unknown
 */
var getAnalyticsHost = function () {

    if ( BUILD_TARGET === "blinds" || BUILD_TARGET === "blindsca" || BUILD_TARGET === "justblinds" || BUILD_TARGET === "americanblinds" ) {

        if ( window._satellite ) {
            return 'LAU';
        }
        
    }

    let scripts = document.scripts, 
        script = '';

    for ( let i = 0; i < scripts.length; i++ ) {

        script = (typeof scripts[i].src === 'string' ? scripts[i].src : '');

        if (script.indexOf("assets.homedepot-static.com/analytics/") > -1 ){
            return 'GCP';
        }
        else if (script.indexOf("assets.thdstatic.com/analytics/") > -1 ){
            return 'AKA';
        }
        else if ( script.indexOf("nexus.ensighten.com/") > -1 ) {
            return 'ENS';
        }
   
    }
    
    return '';    
}

var getMobiledevice = function () {
    var deviceIphone = "iphone";
    var deviceIpod = "ipod";
    var deviceIpad = "ipad";
    var engineWebKit = "webkit";
    var deviceAndroid = "android";
    var deviceWinPhone = "windows phone";

    //Initialize our user agent string to lower case.
    var uagent = navigator.userAgent.toLowerCase();

    if ( uagent.search( deviceIphone ) > -1 ) {
        return deviceIphone;
    }
    if ( uagent.search( deviceIpod ) > -1 ) {
        return deviceIpod;
    }
    if ( uagent.search( deviceIpad ) > -1 ) {
        return deviceIpad;
    }
    if ( uagent.search( deviceAndroid ) > -1 ) {
        return deviceAndroid;
    }
    if ( uagent.search( deviceWinPhone ) > -1 ) {
        return deviceWinPhone;
    }
    if ( uagent.search( engineWebKit ) > -1 ) {
        return engineWebKit;
    }
    return "";
};

var getMobileAppVersion = function () {
     /*Sample Consumer App User Agent    
      - "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 THDConsumer/7.34.1.2 (iPhone;iOS 17.5.1) DID:9503DAF9-8E33-5A2E-3D75-0A1EF75C390F"
      - Would return 7.34.1.2
     */
    
    //Initialize our user agent string to lower case.
    let uagent = navigator.userAgent.toLowerCase(),
        version = uagent.split("thdconsumer/");

    return ( Array.isArray( version ) && version.length ? version[1].split(" ")[0] : "");
}


/**
 * Determines we are running in Consumer App
 * @returns {boolean}: true/false
 */
var isConsumerApp = function () {

    return window.navigator.userAgent.toLowerCase().indexOf("thdconsumer/") !== -1
    
};

/**
 * Determines we are running on the nucleus platform
 * @param pg {string}: ddo.page.pageInfo.platform
 * @param pg {string}: ddo.appExperience
 * @returns {boolean}: true/false
 */
var isNucleus = function ( platform , experience,  expmetadata ) {
    let found = false,
        expmetaname = ( typeof ( expmetadata ) !== "undefined" && expmetadata.name ? expmetadata.name : "" );

    if ( typeof (platform) !== "undefined" || 
         ( Array.isArray(experience) && experience.length && experience[0].name !== 'collage' ) ||
         ( expmetaname && ( expmetaname !== 'collage' && expmetaname !== 'bass-colorwall')) ) {

        found = true;

    }

    return found;
};

/**
 * Determines if the given page name refers to a PIP page
 * @param pg {string}: The page name to evaluate
 * @returns {boolean}: true/false
 */
var isPIP = function ( pg ) {
    pg = ( pg && typeof ( pg ) === 'string' ? pg.toLowerCase() : "" );

	let ss = pg.substr( 0, 15 );

    return ( ss === "productdetails>" );
};


/**
 * List values (array) within a given object, no matter level of JSON complexity
 * @param objData
 * @param out - can pass in an empty array or nothing at all if no matchKey
 * @param matchKey optional string value, if found, will return all values of that key found
 * @returns {*|Array}
 * examples:
 *   getAllValues(digitalData.transaction,[],'sku')
 *       > this returns all values of keys named "sku" in the transaction node of digitalData
 *   getAllValues(digitalData)
 *       > this returns all values of all keys in digitalData object (used to see if any have values assigned (digitalData in use)
 */
var getAllValues = function ( objData, out = [], matchKey = '') {

	let keyVal;

    for ( var key in objData ) {
        keyVal = objData[ key ];

        var nodetype = typeof ( objData[ key ] );
        if ( nodetype === 'object' || nodetype === 'array' ) {
            out = getAllValues( objData[ key ], out, matchKey );
        }
        else {
            if ( keyVal !== undefined && typeof ( keyVal ) !== "function" ) {
                if ( matchKey === '' || matchKey && matchKey === key ) {
                    out.push( keyVal );
                }
            }
        }
    }
    return ( out );
};

/**
 * Evaluate object for any defined values
 * @param objToInspect
 * @returns {boolean} Does object have any defined values
 */
var objHasValue = function ( objToInspect ) {
    var arrAllValues = getAllValues( objToInspect );
    return arrAllValues.length > 0;
};

/**
 * Window Orientation for prop 34
 * @param:
 * @returns:
 */
var getWindowOrientation = function () {
    var theOrientation = "";

	/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    try {
        if ( window.innerWidth > window.innerHeight ) {
            theOrientation = "landscape";
        }
        else {
            theOrientation = "portrait";
        }
    }
    catch ( e ) {}

	return theOrientation;

};


/**
 * Determines if the current page is part of the MyCart/Nucleus Cart Overlay Application running in an iFrame
 * @returns {boolean}: true/false
 */
var isOverlay = function () {
    const _PATH = window.location.pathname.toLowerCase(),
          isMyCartOverlay = (  _PATH === '/mycart/overlay' && window.location !== window.parent.location ),
          isNucleusCartOverlay = ( _PATH.indexOf('/cart/') !== -1 && window.location !== window.parent.location );
    return ( isMyCartOverlay || isNucleusCartOverlay );      
};


/**
 * getValuesFromObj provides mechanism to retrieve values from object
 * @param attribute This is the attribute needed, can be dot notation for deeper values (no more than 4)
 * @param collection direct reference to array object containing single/multiple nodes w/attributes
 * @param delimiter comma delimited by default
 * @returns {string} values of all attribute values found in collection
 */
var getValuesFromObj = function ( attribute, collection, delimiter ) {

    var item, val, atrAry, attr1, attr2, attr3, attr4, cItm,
        attribList = [],
        delimit = delimiter ? delimiter : ',';

    try {
        //accept dot-notation attribute reference
        if ( attribute.indexOf( '.' ) > -1 ) {
            atrAry = attribute.split( '.' );
        }
        else {
            atrAry = [ attribute ];
        }

        for ( item in collection ) {
            cItm = collection[ item ];
            //prep delimiter to prepend values if result already has values
            delimit = delimiter ? delimiter : ',';
            if ( atrAry ) {
                attr1 = atrAry[ 0 ];
                attr2 = ( atrAry.length === 2 ? atrAry[ 1 ] : null );
                attr3 = ( atrAry.length === 3 ? atrAry[ 2 ] : null );
                attr4 = ( atrAry.length === 4 ? atrAry[ 3 ] : null );
                if ( attr4 ) {
                    val = ( cItm[ attr1 ][ attr2 ][ attr3 ][ attr4 ] );
                }
                else if ( attr3 ) {
                    val = ( cItm[ attr1 ][ attr2 ][ attr3 ] );
                }
                else if ( attr2 ) {
                    val = ( cItm[ attr1 ][ attr2 ] );
                }
                else if ( attr1 ) {
                    val = ( cItm[ attr1 ] );
                }
                if ( val ) {
                    attribList.push( val );
                    val = '';
                }

            }
        }
        return attribList.join( delimit );
    }
    catch ( e ) {
        console.log( 'ANALYTICSLOG -- **** getValuesFromObj failed - attribute specified likely not defined', e );
    }
};


let redactEmail = function( item ) {
	if ( item.indexOf( "@" ) > -1 && item.indexOf( "." ) > -1 ) {
		return "email address";
	} else {
		return item;
	}
};


/* eslint-disable */
/**
 * Lower case function to ensure that everything in S object that can be lower cased before being sent.
 */
var lowercaseAndCleanse = function () {

	/* Stop loop at 243 since, eVar244 Neustar fabrick id, eVar245 LP session ID, eVar249 (ECR Party ID) and eVar250 (google id) need to be passed as is */
	for ( var a = 1; a <= 243; a++ ) {

		// Props
		if ( s[ "prop" + a ] && typeof ( s[ "prop" + a ] ) === 'string' ) {
			s[ "prop" + a ] = redactEmail( s[ "prop" + a ].toLowerCase().replace( /^d=/, 'D=' ).replace( /evar/, 'eVar' ).replace( /=pagename/, '=pageName' ) );
		} 

		// Evars
		if (s[ "eVar" + a ] && typeof ( s[ "eVar" + a ] ) === 'string' ) {
			if ( a !== 183 ) {
				s[ "eVar" + a ] = s[ "eVar" + a ].toLowerCase().replace( /^d=/, 'D=' ).replace( /evar/, 'eVar' ).replace( /=pagename/, '=pageName' );
			}
			s[ "eVar" + a ] = redactEmail( s[ "eVar" + a ] );
		}

		if ( a <= 5 && s[ "hier" + a ] && typeof ( s[ "hier" + a ] ) === 'string' ) {
			s[ "hier" + a ] = redactEmail( s[ "hier" + a ].toLowerCase().replace( /^d=/, 'D=' ).replace( /evar/, 'eVar' ).replace( /=pagename/, '=pageName' ) );
		}

	}

	for ( var b = [ "products", "pageName", "channel", "campaign", "state", "purchaseID", "transactionID" ], a = 0; a < b.length; a++ ) {
	
		if( s[ b[ a ] ] && typeof ( s[ b[ a ] ] ) === 'string' ) {
			s[ b[ a ] ] = redactEmail( s[ b[ a ] ].toLowerCase().replace( /^d=/, 'D=' ).replace( /evar/g, 'eVar' ).replace( /=pagename/, '=pageName' ) );
		}

	}

};
/* eslint-enable */


/**
 * For appending event metrics: similar to s.apl, but waaay better
 * @param {string} oldList Reference to s.events (or test string)
 * @param {string} newList Comma Delimited string of events to add (can be number only: '15' instead of 'event15')
 * @returns {string|*}
 */
var apl = function ( newList, oldList ) {
    var inEvents = [],
        outEvents = [],
        i = 0,
        mergeList;
    oldList = typeof oldList !== "undefined" ? oldList : s.events ? s.events : '';
    //if multiple values, split to array and add "event" before numbers
    inEvents = newList.split( ',' );
    for ( i; i < inEvents.length; i++ ) {
        //if setParam IS a number then prepend with "event"
        if ( inEvents[ i ] && isNaN( inEvents[ i ] ) === false ) {
            inEvents[ i ] = "event" + inEvents[ i ];
        }
        //If value is not already in s.events then push into output
        ////adding a comma to end list to assure matches (so all end with comma)
        if ( ( oldList + ',' ).indexOf( inEvents[ i ] + ',' ) === -1 ) {
            outEvents.push( inEvents[ i ] );
        }
    }
    mergeList = outEvents.toString();
    if ( oldList ) {
        if ( mergeList ) {
            mergeList = oldList + ',' + mergeList;
        }
        else {
            mergeList = oldList;
        }
    }
    return mergeList;
};

/**
 * Removes all occurrences of an item from a given list
 * @param {string} list The list to remove the item from
 * @param {string} value The value to remove from the list
 * @returns {string|*} List without any occurrences of the given item
 */
var listRemove = function ( list, value, delim = "," ) {

    list = ( typeof ( list ) === 'string' ? list : '' );
    
    const listAsArray = list.split( delim );

    const result = listAsArray.filter(item => item !== value);
    
    return  result.join( delim );
  }


/**
 *
 * @param:
 * @returns:
 */
var validateDataObj = function ( dObj ) {

    dObj.page = dObj.page ? dObj.page : {};
    dObj.page.myAccount = dObj.page.myAccount ? dObj.page.myAccount : {};
    dObj.page.pageInfo = dObj.page.pageInfo ? dObj.page.pageInfo : {};
    dObj.page.category = dObj.page.category ? dObj.page.category : {};
    dObj.page.onsiteSearchInfo = dObj.page.onsiteSearchInfo ? dObj.page.onsiteSearchInfo : {};
    dObj.page.myList = dObj.page.myList ? dObj.page.myList : {};
    dObj.site = dObj.site ? dObj.site : {};
    dObj.site.businessType = dObj.site.businessType ? dObj.site.businessType : "";
    dObj.site.experienceType = dObj.site.experienceType ? dObj.site.experienceType : "";
    dObj.site.siteVersion = dObj.site.siteVersion ? dObj.site.siteVersion : "";
    dObj.user = dObj.user ? dObj.user : {};
    dObj.user.localization = dObj.user.localization ? dObj.user.localization : {};
    dObj.user.profile = dObj.user.profile ? dObj.user.profile : {};
    dObj.user.profile.profileInfo = dObj.user.profile.profileInfo ? dObj.user.profile.profileInfo : {};
    dObj.user.b2bProfile = dObj.user.b2bProfile ? dObj.user.b2bProfile : {};
    dObj.user.b2bProfile.perks = dObj.user.b2bProfile.perks ? dObj.user.b2bProfile.perks : {};
    dObj.user.b2bProfile.preferredPricing = dObj.user.b2bProfile.preferredPricing ? dObj.user.b2bProfile.preferredPricing : {};

    dObj.cart = dObj.cart ? dObj.cart : {};
    dObj.cart.price = dObj.cart.price ? dObj.cart.price : {};
    dObj.transaction = dObj.transaction ? dObj.transaction : {};
    dObj.transaction.profile = dObj.transaction.profile ? dObj.transaction.profile : {};
    dObj.transaction.profile.address = dObj.transaction.profile.address ? dObj.transaction.profile.address : {};
    dObj.transaction.price = dObj.transaction.price ? dObj.transaction.price : {};
    dObj.product = dObj.product ? dObj.product : [];
    dObj.postOrder = dObj.postOrder ? dObj.postOrder : {};

	if ( BUILD_TARGET === "blinds" || BUILD_TARGET === "blindsca" || BUILD_TARGET === "justblinds" || BUILD_TARGET === "americanblinds" ) {
		dObj.event = ( dObj.event && dObj.event.constructor === Array ? dObj.event : [] );
	}

    return dObj;

};

let isDuplicate = function ( pageName = null ) {

    let result = false;
    let lastEntry = '';

    if ( pageName !== null ) {

        _T.PUB_SUB.broadcastArchive.length && _T.PUB_SUB.broadcastArchive.forEach( function ( broadcast ) {
                        
            if ( broadcast.name === "thdcoreanalytics|beacon" && broadcast.data && ( broadcast.data.beaconType === 't' && broadcast.data.trigger.indexOf('overlay*') === -1 ) ) {
                lastEntry = ( broadcast.data.pageName ? broadcast.data.pageName : '' );
            }

        } );

        if ( lastEntry ) {

            result = ( pageName.toString().toLowerCase() === lastEntry.toString().toLowerCase() );

        }

    }
    
	return result;

};

var getUUID = function () {

    var hex = [];

    for ( var i = 0; i < 256; i++ ) {
        hex[ i ] = ( i < 16 ? '0' : '' ) + ( i ).toString( 16 );
    }

    function makeUUID() {
        var r = crypto.getRandomValues( new Uint8Array( 16 ) );

        /* jshint bitwise: false */
        r[ 6 ] = r[ 6 ] & 0x0f | 0x40;
        r[ 8 ] = r[ 8 ] & 0x3f | 0x80;
        /* jshint bitwise: true */

        return (
            hex[ r[ 0 ] ] +
            hex[ r[ 1 ] ] +
            hex[ r[ 2 ] ] +
            hex[ r[ 3 ] ] +
            "-" +
            hex[ r[ 4 ] ] +
            hex[ r[ 5 ] ] +
            "-" +
            hex[ r[ 6 ] ] +
            hex[ r[ 7 ] ] +
            "-" +
            hex[ r[ 8 ] ] +
            hex[ r[ 9 ] ] +
            "-" +
            hex[ r[ 10 ] ] +
            hex[ r[ 11 ] ] +
            hex[ r[ 12 ] ] +
            hex[ r[ 13 ] ] +
            hex[ r[ 14 ] ] +
            hex[ r[ 15 ] ]
        );
    }

    if ( ( typeof crypto !== "undefined" ) && ( typeof crypto.getRandomValues === "function" || typeof crypto.getRandomValues === "object" ) ) {

        return makeUUID();

    }
    else {

        return Math.floor( Math.random() * Math.floor( 10000000000000000000 ) );

    }

};

var snapshotInstanceOfDigitalData = async function( digitalData ) {

    var instanceID = getUUID();
    var validatedDDO = validateDataObj( digitalData );
    let ddoInstance = {};
	let clone;
	let cloneDeep;

    try {

		// We only want one file for GCC with no dynamic imports.
		if ( BUILD_TARGET === "blinds" || BUILD_TARGET === "blindsca" || BUILD_TARGET === "justblinds" || BUILD_TARGET === "americanblinds" ) {
			clone = staticLodashCloneDeep;
		} else {
			clone = await import( /* webpackChunkName: "lodash", webpackPrefetch: true */ 'lodash-es/cloneDeep' );
		}

		cloneDeep = clone.default;

        console.log( "ANALYTICSLOG -- Lodash is loaded: ", clone );

        ddoInstance = cloneDeep( validatedDDO );
        ddoInstance.instanceIsDeepClone = true;
        ddoInstance.instanceID = instanceID;
        appData.addUpdateDDOInstance( instanceID, ddoInstance );

        return {
            "id": instanceID,
            "ddo": ddoInstance
        };

    } catch ( error ) {

        console.warn( "ANALYTICSLOG -- Unable to Create Deep Clone of digitalData" );
        console.error( `ANALYTICSLOG -- Error: ${ error }` );

        ddoInstance = validatedDDO;
        ddoInstance.instanceIsDeepClone = false;
        ddoInstance.instanceID = instanceID;
        appData.addUpdateDDOInstance( instanceID, ddoInstance );

        return {
            "id": instanceID,
            "ddo": ddoInstance
        };
    }

};

export {
    write,
    read,
    readCookieKeyValue,
    checkArray,
    getAnalyticsHost,
    getMobileAppVersion,
    getMobiledevice,
    isConsumerApp,    
    isNucleus,
    isPIP,
    objHasValue,
    getWindowOrientation,
    getValuesFromObj,
	lowercaseAndCleanse,
    isOverlay,
    apl,
    listRemove,
    validateDataObj,
    isDuplicate,
    snapshotInstanceOfDigitalData,
    getUUID,
	cookieRead
};
