import * as utilities from "../src/utilities";

const site = "b2consumer-desktop";
const client = "homedepot";
const publishPath = "prod";


let toggleSwitches = {};

let getPublishPath = function () {

	return publishPath;

};

let getClient = function () {

	return client;

};

let isProdDeployment = function () {

    let pubPath = getPublishPath();
    let client = '';
    let isProdDomain = false;

    
    if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2consumer-mobile" || BUILD_TARGET === "b2business-desktop" || BUILD_TARGET === "b2business-mobile" ) {
        
        isProdDomain = location.hostname.indexOf('homedepotdev.com' ) === -1;

    }  else if ( BUILD_TARGET === "tcs" ) {

        isProdDomain = location.hostname.indexOf('www.thecompanystore.com') > -1;

    } else {

        client = getClient();

        isProdDomain = ( window._satellite?.environment?.stage === "production" || location.hostname.indexOf('www') > -1 ) && client === 'blinds';

    }   


    if ( (pubPath === 'prod' || pubPath === 'b2bprod' || pubPath === 'b2xprod') && isProdDomain ) {
        return true;
    }

    return false;

};

let mobileToggleSwitches = {
    "betaMode": {
        "enabled": ( function () {
            return ( document.cookie.indexOf( 'HD_DC=beta' ) > -1 );
        }() )
    },
    "perimeterx": {
        "enabled": true
    },
    "failSafeBeacon": {
        "enabled": ( function () {
            var targetURLs = [
                '/c/',
                'vanityselections.homedepot.com',
                '/Tilescapes/',
                'lightscapes',
                '/pmm/',
                '/fencing-designs/',
                '/rebatefinder/index.html',
                '/PlatinumPlus/index.html',
                '/mix-and-match-lamps/',
                'toro.homedepot.com'
            ];

            //if path is in exceptions then return true
            if ( ( utilities.checkArray( targetURLs, window.location.host, window.location.pathname ) ) || ( ( window.location.host === "www.homedepot.com" ) && ( window.location.pathname === "/" ) ) ) {
                return true;
            }
            else {
                return false;
            }
        }() )

    },
	"activityMap": {
		"enabled": ( function() {
			let excludeURLs = [
				'/account/view/instantcheckout',
				'/account/view/paymentcards',
				'/auth/view/checkout/signin',
				'/auth/view/forgotpassword',
				'/auth/view/signin',
				'/mycart/home',
                '/cart/',
				'/mycheckout/checkout',
				'/mycheckout/toolrental'
			];

			//if path is in exceptions then return true
			if ( utilities.checkArray( excludeURLs, window.location.host, window.location.pathname ) ) {
				return false;
			}
			else {
				return true;
			}
		}() )
    },
    "impressionProvider": {
        "enabled": true
    },
    "ecridsync": {
        "enabled": true
    }
};

let desktopToggleSwitches = {
    "betaMode": {
        "enabled": ( function () {
            return ( document.cookie.indexOf( 'HD_DC=beta' ) > -1 );
        }() )

    },
    "perimeterx": {
        "enabled": true
    },
    "failSafeBeacon": {
        "enabled": ( function () {
            let targetURLs = [
                '/c/steps_to_weatherproofing_your_windows_HT_PG_BM',
                'vanityselections.homedepot.com',
                '/Tilescapes/',
                'lightscapes',
                '/pmm/',
                '/fencing-designs/',
                '/rebatefinder/index.html',
                '/PlatinumPlus/index.html',
                '/mix-and-match-lamps/',
                'toro.homedepot.com'
            ];

            //if path is in exceptions then return true
            if ( utilities.checkArray( targetURLs, window.location.host, window.location.pathname ) ) {
                return true;
            }
            else {
                return false;
            }
        }() )
    },
	"activityMap": {
		"enabled": ( function() {
			let excludeURLs = [
				'/account/view/instantcheckout',
				'/account/view/paymentcards',
				'/auth/view/checkout/signin',
				'/auth/view/forgotpassword',
				'/auth/view/signin',
				'/mycart/home',
                '/cart/',
				'/mycheckout/checkout',
				'/mycheckout/toolrental'
			];

			//if path is in exceptions then return true
			if ( utilities.checkArray( excludeURLs, window.location.host, window.location.pathname ) ) {
				return false;
			}
			else {
				return true;
			}
		}() )
    },
    "impressionProvider": {
        "enabled": true
    },
    "ecridsync": {
        "enabled": true
    }
};

/**
 * Determines the beacon delay based on the page name
 * @param pgNm {string}: The page name to evaluate
 * @returns {number}: the delay in milliseconds to use for the given page name
 */

let getPgBeaconDelay = function ( pgNm ) {

    pgNm = ( pgNm ? pgNm.toLowerCase() : "" );

    if ( site === "b2consumer-mobile" || site === "b2business-mobile" || site === "b2x-mobile" ) {
        return 1500; // default
    }
    else if ( site === "b2consumer-desktop" || site === "b2business-desktop" || site === "b2x-desktop" ) {
        if ( pgNm === "list details" || pgNm === "my list details" ) {
            return 1500;
        }
        else {
            return ( utilities.isPIP( pgNm ) ? 2000 : 0 );
        }
    }

    return 0;

};

/**
 * reads the toggles and sets the feature accordingly.
 */
( function () {

    if ( site === "b2consumer-desktop" || site === "b2business-desktop" || site === "b2x-desktop" ) {
        toggleSwitches = desktopToggleSwitches;
    }
    else {
        toggleSwitches = mobileToggleSwitches;
    }

    /**
     * Sets the throttle based on configuration
     * @param {number} throttle
     * @returns {boolean}
     */
    //wrap in function whether true or false
    var checkForThrottle = function ( throttle = 0, key = "unknown" ) {

        let rand = parseInt( ( Math.random() * 100 ) );

        if ( rand < throttle ) {

            utilities.write( key, "true" );

            return true;

        }
        else {

            utilities.write( key, "false" );

            return false;

        }

    };

    var throttle = function ( objToggles ) {
        //iterate through object. if feature has throttle defined, check cookie and override enabled if set.

        for ( var switchNode in objToggles ) {

            if ( objToggles.hasOwnProperty( switchNode ) ) {

                var featureThrottle = objToggles[ switchNode ].throttle;

                if ( featureThrottle ) {

                    //check if already set in cookie
                    var readThrottle = utilities.read( switchNode );

                    if ( readThrottle ) {

                        if ( readThrottle === "true" ) {
                            objToggles[ switchNode ].enabled = true;
                        }
                        else {
                            objToggles[ switchNode ].enabled = false;
                        }

                    }
                    else {
                        objToggles[ switchNode ].enabled = checkForThrottle( featureThrottle, switchNode );
                    }
                }
            }
        }
    };

    throttle( toggleSwitches );

}() );

/**
 * Check if feature enabled
 * @param {string} featureName
 * @param {object} [toggles] -json object
 * @returns {boolean}
 */
let featureEnabled = function ( featureName, toggles ) {

    toggles = toggles ? toggles : toggleSwitches; //todo: enforce camel case naming convention.

    for ( var feature in toggles ) {
        if ( toggles.hasOwnProperty( feature ) ) {
            if ( featureName === feature ) {
                return toggles[ feature ].enabled;
            }
        }
    }
    return false;
};

const getSAccount = function( ddo ) {

    let isMilitaryExchange = ( location.hostname.indexOf('exchange.homedepot.com' ) !== -1 ? true : false ),
        suitesSet = new Set();

	utilities.validateDataObj( ddo );

	if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2consumer-mobile" || BUILD_TARGET === "b2business-desktop" || BUILD_TARGET === "b2business-mobile" ) {

		if ( isProdDeployment() ) {

			suitesSet.add( "homedepotglobalproduction" );

			if ( ddo.site.businessType.toString().toLowerCase() === "b2b" || ddo.site.businessType.toString().toLowerCase() === "b2x" ) {
				suitesSet.add( "homedepotb2bprod" );
			}

            if ( isMilitaryExchange ) {
                suitesSet.add( "homedepotmilitary" );
            }

		}
		else {

			suitesSet.add( "homedepotglobaldev" );

			if ( ddo.site.businessType.toString().toLowerCase() === "b2b" || ddo.site.businessType.toString().toLowerCase() === "b2x" ) {
				suitesSet.add( "homedepotb2bdev" );
			}

		}

	} else if ( BUILD_TARGET === "tcs" ) {

		if ( isProdDeployment() ) {
			suitesSet.add( "Homethecompanystoreprod" );
		} else {
			suitesSet.add( "Homethecompanystoredev" );
		}

	}
	else {

		if ( BUILD_TARGET === "americanblinds" ) {

			if ( isProdDeployment() ) {
				suitesSet.add( 'blindscenturyprod' );
			}
			else {
				suitesSet.add( 'blindsamericandevtest' );
			}

		}

		if ( BUILD_TARGET === "blinds" ) {

			if ( isProdDeployment() ) {
				suitesSet.add( 'blindsmaincom' );
			}
			else {
				suitesSet.add( 'blindscom1dev' );
			}

		}

		if ( BUILD_TARGET === "blindsca" ) {

			if ( isProdDeployment() ) {
				suitesSet.add( 'blindscamain' );
			}
			else {
				suitesSet.add( 'blindscadevtest' );
			}

		}

		if ( BUILD_TARGET === "justblinds" ) {

			if ( isProdDeployment() ) {
				suitesSet.add( 'blindsjustblindscom' );
			}
			else {
				suitesSet.add( 'blindscom2dev' );
			}

		}

	}

	let uniqueSuites = Array.from( new Set( suitesSet ) );

	// We want to remove the B2B report suites for Native Apss for now
	if ( document.cookie.indexOf( 'CNative' ) > -1 ) {

		let indexOfRSID = uniqueSuites.indexOf( 'homedepotb2bprod' );
		indexOfRSID = indexOfRSID > -1 ? indexOfRSID : uniqueSuites.indexOf( 'homedepotb2bdev' );

		if ( indexOfRSID > -1 ) {
			uniqueSuites.splice( indexOfRSID, 1 );
		}

	}

	return uniqueSuites.join();

};


export {
    site,
    getPublishPath,
    getSAccount,
    getClient,
    isProdDeployment,
    getPgBeaconDelay,
    featureEnabled
};
