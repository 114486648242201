import { isProdDeployment } from './config';

/*
Prod - x540zSAbXJfU1e1OIhrNRk9t0aqF0vpu - https://www.homedepot.com/uds/v1/mapping?key=x540zSAbXJfU1e1OIhrNRk9t0aqF0vpu&anchor=03ed1de74b71aa700s&type=ecrId,hhID
LLC - R0QTGGrYosXcuas42kdAx7RVGowBhveJ - https://uds-api.hd-personalization-stage.gcp.homedepot.com/uds/v1/mapping?key=R0QTGGrYosXcuas42kdAx7RVGowBhveJ&anchor=03ed1de74b71aa700s&type=ecrId,hhID
*/ 
const _env = ( isProdDeployment() ? 'prod' :  'dev' ),
      _UDSConf = { "prod": {"key": "x540zSAbXJfU1e1OIhrNRk9t0aqF0vpu","endpoint": "www.homedepot.com/uds/v1/mapping"},
                   "dev": { "key": "R0QTGGrYosXcuas42kdAx7RVGowBhveJ", "endpoint": "uds-api.hd-personalization-stage.gcp.homedepot.com/uds/v1/mapping" }};

const init = () => {

  let ecridUDSCalled = s.c_r( "ecridUDSCalled" );
  
  console.log("ecridUDSCalled", ecridUDSCalled);

  if ( _T.svocid && !ecridUDSCalled ) {
    
    getECRIDFromSVOCID ( _T.svocid );
    s.c_w( 'ecridUDSCalled', true ); // Goes in s_sess (expires with user session)

  }
  
  /**
 * Function to grab values from Metarouter index db storage
 * @param {*} dbName 
 * @param {*} storeName 
 * @param {*} key 
 * @param {*} callback 
 */
function getValueFromMrInDb(dbName, storeName, key, callback) {
    // Open a connection to the database
    let request = window.indexedDB && typeof indexedDB.open === "function" ? indexedDB.open(dbName) : "";

    if (typeof request !== "object") {return "";
    }

    request.onsuccess = function (event) {
        const db = event.target.result;
        try {
            const transaction = db.transaction(storeName, "readonly");
            const store = transaction.objectStore(storeName);
            const index = store.index("name");
            const getRequest = index.openCursor(key);

            getRequest.onsuccess = function (event) {
                // Call the callback function with the result
                callback(event?.target?.result?.value?.value);
            };
            store.onerror = function () {
                return "";
            };
        } catch (error) {
          return "";
        }
    };
}


if (!s.c_r("fabrickIdfromInDb")) {
    getValueFromMrInDb(
        "mr",
        "syncs",
        "_mr_neustar_fabrickId",
        function (value) {
            s.c_w("fabrickIdfromInDb", value); // Goes in s_sess (expires with user session)value ?? '';
        }
    );
}

};

const getECRIDFromSVOCID = async ( svocid ) => {
  
  try {

    const controller = new AbortController(),
          signal = controller.signal,
          url =  `https://${ _UDSConf[_env].endpoint }?key=${ _UDSConf[_env].key }&anchor=${ svocid.toString().toUpperCase() }&type=ecrId,hhId`;
    
    setTimeout(() => controller.abort(), 5000);

    fetch( url, { signal })
    .then(response => response.json())
    .then(data => { 

      console.log("[UDS API]:", data);

      if ( typeof ( data.ecrId ) !== 'undefined' ) {
        s.c_w( 'ecridFromSVOCID', data.ecrId ); // Goes in s_sess (expires with user session)
      }
      if ( typeof ( data.hhId ) !== 'undefined') {
        s.c_w( 'hhidFromSVOCID', data.hhId ); // Goes in s_sess (expires with user session)
      }
      return 'ecrID=' + ( data.ecrId ? data.ecrId : '' ) + ',' + 'hhId=' + ( data.hhId ? data.hhId : '' );

    })
    .catch( error => {

      console.error('[UDS API]: There has been a problem with your fetch operation:', error);
      return "";

    });
    
  } catch (e) {

    console.log("[UDS API]: ERROR", e);
    return "";

  }

};


export {
  init
};