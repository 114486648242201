import * as utilities from "../src/utilities";

// Pub/Sub Factory Function
// In factory functions ‘this’ refers to the parent object
var init = function () {

    return {

        subscriptions: {},
        broadcastArchive: [],

        // subscribe to an event
        subscribe: function ( name, func ) {

            var id = utilities.getUUID();

            if ( !this.subscriptions[ name ] ) {
                this.subscriptions[ name ] = [];
            }

            this.subscriptions[ name ].push( {
                "id": id,
                "action": func
            } );

            return id;

        },

        // unsubscribe from an event
        unsubscribe: function ( id ) {

            for ( var sub in this.subscriptions ) {

                var subscribers = this.subscriptions[ sub ];

                for ( var i = 0; i < subscribers.length; i++ ) {

                    if ( subscribers[ i ].id === id ) {
                        subscribers.splice( i, 1 );
                        break;
                    }

                }

            }

        },

        // publish an event
        publish: function ( name, args ) {

            var audience = this.subscriptions[ name ];

            this.broadcastArchive.push( {
                "name": name,
                "data": args
            } );

            if ( audience ) {

                audience.forEach( function ( subscriber ) {

                    var action = subscriber.action;

                    if ( typeof action === 'function' ) {
                        action.call( null, args );
                    }

                } );

            }

        }

    };

};

export {
    init
};
