import * as beaconController from "./beaconController";
import * as utilities from "./utilities";
import * as config from "./config";
import * as appData from "./appData";

// Iterates over event array object to find unreported events,
let getNewEvents = function ( ddo ) {

    let newEvents = [],
        eventArray = ddo.event;

    if ( eventArray && eventArray.length > 0 ) {
        for ( let index = 0; index < eventArray.length; index++ ) { //anEvent in eventArray) {
            if ( eventArray[ index ] && !eventArray[ index ].isReported && eventArray[ index ].eventInfo.eventName ) {

                eventArray[ index ].isReported = true;
                eventArray[ index ].sourceIndex = index;

                // These have to be added to the global/original digitalData object.
                digitalData.event[ index ].isReported = true;
                digitalData.event[ index ].sourceIndex = index;

                newEvents.push( eventArray[ index ] );

            }
        }
    }

    return newEvents;

};

// used to support product impression tracking and any other internally triggered events.
let processInternalEvents = async function( ddoParam ) {

    let ddoInstance = await utilities.snapshotInstanceOfDigitalData( ddoParam );
    let ddo = ddoInstance.ddo;

    let internalEvents = appData.getInternalEvents();

    for ( let i = 0; i < internalEvents.length; i++ ) {

        let eventObject = {},
            eventIndex;

        ddo.event[ ddo.event.length ] = internalEvents[ i ]; // add without triggering callback to avoid circular processing

        eventIndex = ddo.event.length - 1;
        eventObject = ddo.event[ eventIndex ];
        eventObject.isReported = true;
        eventObject.sourceIndex = eventIndex;

        await beaconController.report( ddo, ddoInstance.id, eventObject.category.primaryCategory, eventObject.eventInfo.eventName, eventIndex );

    }

    appData.clearInternalEvents();

};

// stash/preload/report new not-yet-processed events
let processEvent = async function( eventIndex, ddoInstance ) {

    // These have to be added to the global/original digitalData object.
    digitalData.event[ eventIndex ].isReported = true;
    digitalData.event[ eventIndex ].sourceIndex = eventIndex;

    ddoInstance = await ( typeof ddoInstance !== 'undefined' ? ddoInstance : utilities.snapshotInstanceOfDigitalData( window.digitalData ) );

    let ddo = ddoInstance.ddo;

    eventIndex = ( typeof eventIndex !== 'undefined' ? eventIndex : -1 );

    var eventObject = ( ddo.event && eventIndex !== -1 && ddo.event[ eventIndex ] ? ddo.event[ eventIndex ] : {} ),
        eventMode = ( eventObject.eventMode && typeof ( eventObject.eventMode ) === 'string' ? eventObject.eventMode.toLowerCase().replace( / /g, '' ) : '' );

    if ( utilities.objHasValue( eventObject ) && eventMode === 'preload' ) {

        //Add to preload array for reporting on pageview
        appData.addPreloadEvents( eventIndex );

    }
    else if ( utilities.objHasValue( eventObject ) ) { //no eventMode handling -> standard process

        if ( eventIndex < 0 ) {

            s.prop37 = "processEvent called with invalid eventIndex";
            s.linkTrackVars = 'prop37,prop55';

			utilities.lowercaseAndCleanse();
            s.tl( true, 'o', 'implement error' );

        }
        else {

            let _primaryCategory = ( eventObject.category && eventObject.category.primaryCategory ? eventObject.category.primaryCategory : '' ),
                _eventName = ( eventObject.eventInfo && eventObject.eventInfo.eventName ? eventObject.eventInfo.eventName : '' );

            // Provide temporary support for MW checkout error tracking, remove once DC issue resolved
            if ( ( config.site === 'b2consumer-mobile' || config.site === 'b2business-mobile' || config.site === 'b2x-mobile' ) && _primaryCategory === '' && eventObject.category && eventObject.category.category ) {
                _primaryCategory = eventObject.category.category;
            }

            await beaconController.report( ddo, ddoInstance.id, _primaryCategory, _eventName, eventIndex );

            if ( _primaryCategory === 'cart view modification' ) {
                appData.addUpdateCache( "triggerPageView", false );
            }

            let internalEvents = appData.getInternalEvents();

            // used to support product impression tracking and any other internally triggered event.
            if ( internalEvents.length ) {
                window.setTimeout( processInternalEvents, 2000, ddo, ddoInstance.id );
            }
        }

    }

};

/**
 * Internal method returns custom array object with call to processEvent on push
 * @returns {DataArray} Call returns custom array object, push to array object returns index of addition
 * @private
 */
let eventDataArray = function () {

    let DataArray = function () {
        let arr = [];
        arr.push = function () {

            //trigger processEvent
            Array.prototype.push.apply( this, arguments );

            processEvent( arr.length - 1 ); //pass proper zero based index into callback

            return arr.length;
        };
        return arr;
    };
    return new DataArray();
};

// API method exposed for external IT teams to trigger analytics event reporting
let sendEventDataToAnalytics = function ( eventObj ) {

    digitalData.event = ( digitalData.event ? digitalData.event : [] );        
    digitalData.event.push( eventObj );

    if ( BUILD_TARGET === "blinds" || BUILD_TARGET === "blindsca" || BUILD_TARGET === "justblinds" || BUILD_TARGET === "americanblinds" ) {

        // For GCC eventDataArray doesn't exist and is therefore never assigned to digitalData.event.  
        // Consequently, pushing to digitalData.event on GCC doesn't trigger any processing action.
        // So we explicitly call processEvent for GCC.

        processEvent( digitalData.event.length - 1 ); //pass proper zero based index into callback
        
    }

    return true;
};

// Processes all events that are in _T.eventData or ddo.event; primarily used to capture any events that were pushed before the async code loaded
let processPreIgnitionEvents = async function( eventArray ) {
    let count = 0;

    if ( Array.isArray( eventArray ) ) {
        count = eventArray.length;
        for ( let i = 0; i < count; i++ ) {

            if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2consumer-mobile" || BUILD_TARGET === "b2business-desktop" || BUILD_TARGET === "b2business-mobile" || BUILD_TARGET === "tcs" ) {
                await digitalData.event.push( eventArray[ i ] );
            }

            if ( BUILD_TARGET === "blinds" || BUILD_TARGET === "blindsca" || BUILD_TARGET === "justblinds" || BUILD_TARGET === "americanblinds" ) {
                sendEventDataToAnalytics( eventArray[ i ] );
            }

        }
    }
    return count; //number of preignition events processed
};

export {
    eventDataArray,
    sendEventDataToAnalytics,
    getNewEvents,
    processEvent,
    processPreIgnitionEvents,
    processInternalEvents
};
