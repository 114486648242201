import * as utilities from "../src/utilities";
import * as config from "../src/config";
import * as appData from "../src/appData";

var init = function () {

    // Performs the push to page data array, just in case FED does not.
    var defaultPageDataPush = function () {
        var delay = config.getPgBeaconDelay( digitalData.page.pageInfo.pageName ? digitalData.page.pageInfo.pageName : "" );

        window.setTimeout( function () {
            // check if push happened externally one more time before triggering push ourselves
            if ( !appData.getFromCache( "pageDataPushed" ) ) {

                appData.addUpdateCache( "beaconExecutionType", 'fail-safe' );

                console.log( "ANALYTICSLOG -- failSafe -- defaultPageDataPush -- _T.pageData.push" );

                _T.pageData.push( digitalData );

            }
        }, delay );
    };

    /**
     * The following block governs when we trigger the default page push.
     */
    if ( !appData.getFromCache( "pageDataPushed" ) ) {
        // If either DC is present, check if DOMready (already) then fire immediately
        if ( ( utilities.objHasValue( window.digitalData ) ) && document.readyState === "complete" && ( !config.featureEnabled( "perimeterx" ) || ( _T.pxbs ) ) ) {
            defaultPageDataPush();
        }
        else {
            // Check for either DC every 250 milliseconds, for a max of (_maxIntervals * 250) seconds.  Trigger the default push if we find either DC or we reach out max number of intervals.
            var _maxIntervals = 8,
                _dcCheckCounter = 0,
                _isDCDefined = function () {
                    if ( ( utilities.objHasValue( window.digitalData ) && ( !config.featureEnabled( "perimeterx" ) || ( _T.pxbs ) ) ) || _dcCheckCounter >= _maxIntervals ) {
                        if ( document.readyState === "complete" ) {
                            defaultPageDataPush();
                            window.clearInterval( _dcCheckTimer );
                        }
                        else if ( _dcCheckCounter >= _maxIntervals && ( config.site === "b2consumer-desktop" || config.site === "b2business-desktop" || config.site === "b2x-desktop" )) {

							/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
                            try {
                                if ( document.documentMode || /Edge/.test( navigator.userAgent ) ) {
                                    defaultPageDataPush();
                                }
                            }
                            catch ( e ) {}

                            window.clearInterval( _dcCheckTimer );
                        }
                    }
                    _dcCheckCounter++;
                };
            /* jshint -W003 */
            var _dcCheckTimer = setInterval( _isDCDefined, 250 );
            /* jshint +W003 */
        }
    }

};

export {
    init
};
